<template>
  <div @click="$refs.modal.open()" class="detail-box" :style="`background-color: ${bg}`">
    <Imgix :src="`${image}?w=640`" :alt="title" loading="lazy" />
    <h3>{{ title }}</h3>
    <slot></slot>
    <sweet-modal :title="title" ref="modal">
      <aside>
        <slot name="image"></slot>
      </aside>
      <slot></slot>
      <slot name="popis"></slot>
      <h4>Cenník</h4>
      <table>
        <thead>
          <tr>
            <th v-for="(pricelistCell, index) in pricelist[0]" :key="`header${index}`">{{ header(index) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pricelistRow, index) in pricelist" :key="`row${index}`">
            <td v-for="(pricelistCell, jindex) in pricelistRow" :key="`row${index}cell${jindex}`">{{ pricelistCell | euro }}</td>
          </tr>
        </tbody>
      </table>
      <template slot="button">
        <a href="https://www.alpinresorts.com/en/ski-rental/partner-ski-ninaj/parameters/3414" target="_blank" title="Rezervovať" class="button-action">Rezervovať</a>
      </template>
    </sweet-modal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'

export default {
  name: 'DetailBox',
  props: {
    title: String,
    image: String,
    pricelist: Array,
    bg: String
  },
  components: {
    SweetModal
  },
  filters: {
    euro: function (value) {
      if (!value)
        return ''
      const floatVal = parseFloat(value)
      if (!floatVal)
        return value
      return `${floatVal} €`
    }
  },
  methods: {
    header(index) {
      if (index === 0) {
        return "Počet dní"
      }
      return index
    }
  }
}
</script>

<style lang="scss" scoped>
  .detail-box {
    cursor: pointer;
  }

  ::v-deep .sweet-modal {
    cursor: default;
    max-width: 850px;
  }

  ::v-deep .sweet-modal .sweet-title > h2 {
    color: #66acca;
  }

  ::v-deep .sweet-modal table tbody td {
    text-align: center;
  }
</style>
