<script>
import DetailBox from '@/components/DetailBox'
import AppFooter from '@/components/AppFooter'

export default {
  name: 'pozicovna',
  components: {
    DetailBox,
    AppFooter
  },
  methods: {
    scrollTo(ref) {
      const element = this.$refs[ref].$el
      window.scrollTo(0, element.offsetTop - 120);
    },
    openModal(n) {
      this.$refs[n].$refs["modal"].open()
    }
  }
};
</script>

<template>
  <div class="pozicovna-view">
    <article>
      <div class="content-wrapper">
        <h1>Požičovňa lyží, snowboardov, alebo kompletnej výstroje</h1>
        
        <h2>Požičovňa lyžiarskej a snowboardovej výstroje</h2>
        
        <p>Ski Niňaj disponuje sieťou 8 prevádzok, ktoré sú pripravené Vás i Vaše deti vybaviť lyžiarskou, snowboardovou, bežkárskou a skialpovou výstrojou. Zakladáme si na maximálnej spokojnosti zákazníkov, preto máme v ponuke výber vybavenia od renomovaných značiek vo všetkých ponúkaných dĺžkach a veľkostiach:</p>
        <div class="logo-boxes">
          <div class="logo-box">
            <span>lyže</span>
            <img src="@/assets/brands/rossignol.svg" alt="Rossignol">
            <img src="/images/logo/salomon.jpg" alt="Salomon">
            <img src="/images/logo/atomic.png" alt="Atomic">
            <img src="/images/logo/volkl.png" alt="Voelkl">
            <img src="/images/logo/head.jpg" alt="Head">
            <img src="/images/logo/k2.jpg" alt="K2" style="max-height: 70px">
            <img src="@/assets/brands/elan.svg" alt="Elan">
            <img src="/images/logo/blizzard.png" alt="Blizzard">
          </div>
          <div class="logo-box">
            <span>lyžiarky</span>
            <img src="@/assets/brands/nordica.svg" alt="Nordica">
            <img src="/images/logo/tecnica.png" alt="Tecnica">
            <img src="/images/logo/lange.jpg" alt="Lange">
          </div>
          <div class="logo-box">
            <span>snowboardy a topánky</span>
            <img src="@/assets/brands/burton.svg" alt="Burton" style="max-height: 75px">
          </div>
          <div class="logo-box">
            <span>bežecká výstroj</span>
            <img src="@/assets/brands/fischer.svg" alt="Fischer" style="max-height: 75px">
          </div>
          <div class="logo-box">
            <span>skialpová výstroj</span>
            <img src="@/assets/brands/dynastar.svg" alt="Dynastar">
          </div>
        </div>

        <p>Pre dokonalý zážitok na snehu každú výstroj z našej ponuky pravidelne kontrolujeme a servisujeme na strojoch MONTANA, ktoré sú svetovou špičkou vo svojom odbore. Náš personál tvoria profesionáli, ktorí sú pravidelne preškolení o novinkách v oblasti lyžiarskej výstroje, jej nastavenia a servisu, a sú pripravení Vám pomôcť bezstarostne si užiť Váš čas v zime na horách. Spoľahnite sa na 26-ročnú tradíciu v poskytovaní služieb pre zimné športy.</p>
      </div>

      <div class="boxes">
        <detail-box title="VIP zjazdové lyže" v-bind:pricelist="[['VIP lyžiarsky komplet', 25, 45, 55, 65, 75, 80], ['VIP lyže', 15, 29, 40, 50, 55, 60]]" 
                    class="box" image="pozicovna/vip-lyze.jpg" bg="#0968aa" ref="1">
          <p>Športovo ladené a exkluzívne lyže pre skúsenejších lyžiarov a maximálny zážitok na zjazdovkách.</p>
          <template slot="popis">
            <p>Požičanie lyží alebo lyžiarskeho kompletu, ktorý okrem lyží pozostáva z lyžiarok a paličiek.</p>
          </template>
          <template slot="image">
            <Imgix src="pozicovna/pozicovna_vip.jpg" alt="VIP zjazdové lyže" />
          </template>
        </detail-box>

        <detail-box title="TOP zjazdové lyže" v-bind:pricelist="[['TOP lyžiarsky komplet', 18, 35, 48, 60, 70, 75]]" 
                    class="box" image="pozicovna/top-lyze.jpg" bg="#6b6aef" ref="8">
          <p>Lyže pre pokročilých s vylepšenými vlastnosťami - pre výnimočný a plný zážitok z jazdy.</p>
          <template slot="popis">
            <p>Požičanie lyží alebo lyžiarskeho kompletu, ktorý okrem lyží pozostáva z lyžiarok a paličiek.</p>
          </template>
          <template slot="image">
            <Imgix src="pozicovna/pozicovna_top.jpg" alt="TOP zjazdové lyže" />
          </template>
        </detail-box>

        <detail-box title="STANDARD zjazdové lyže" v-bind:pricelist="[['STANDARD lyžiarsky komplet', 15, 29, 40, 45, 50, 55], ['STANDARD lyže', 12, 20, 30, 38, 45, 50]]" 
                    image="pozicovna/standard-lyze.jpg" class="box" bg="#01a0e2" ref="2">
          <p>Lyže určené pre rekreačných lyžiarov a začiatočníkov, ktorí hľadajú bezpečnú a intuitívnu výstroj.</p>
          <template slot="popis">
            <p>Požičanie lyží alebo lyžiarskeho kompletu, ktorý okrem lyží pozostáva z lyžiarok a paličiek.</p>
          </template>
          <template slot="image">
            <Imgix src="pozicovna/pozicovna_standard.jpg" alt="STANDARD zjazdové lyže" />
          </template>
        </detail-box>

        <detail-box title="DETSKÉ/JUNIOR zjazdové lyže" v-bind:pricelist="[['Detský komplet', 12, 22, 30, 38, 45, 50]]" class="box" image="pozicovna/junior-lyze.jpg" bg="#41ead4" ref="3">
          <p>Bezpečné a zábavné lyže pre deti a mládež všetkých vekových kategórii.</p>
          <template slot="popis">
            <p>Požičanie lyží alebo lyžiarskeho kompletu, ktorý okrem lyží pozostáva z lyžiarok a paličiek.</p>
          </template>
          <template slot="image">
            <Imgix src="images/pozicovna/pozicovna_detske.jpg" alt="" />
          </template>
        </detail-box>

        <detail-box title="SNOWBOARD" v-bind:pricelist="[['Snowboardový komplet', 18, 35, 48, 59, 69, 75], ['Snowboard', 12, 20, 30, 38, 45, 50]]" class="box" image="pozicovna/snowboard.jpg" bg="#ff9f1c" ref="4">
          <p>Široká ponuka snowboardov a snowboardových topánok.</p>
          <template slot="popis">
            <p>Požičanie snowboardového kompletu, ktorý pozostáva zo snowboardu s viazaním a snowboardových topánok.</p>
          </template>
          <template slot="image">
            <Imgix src="images/pozicovna/pozicovna_snowboard.jpg" alt="" />
          </template>
        </detail-box>

        <detail-box title="BEŽECKÁ VÝSTROJ" v-bind:pricelist="[['Bežecký komplet', 10, 19, 28, 37, 45, 50]]" class="box" image="pozicovna/bezecka-vystroj.jpg" bg="#96d400" ref="5">
          <p>Výstroj na bežecké lyžovanie - klasika I korčuľovanie.</p>
          <template slot="popis">
            <p>Požičanie bežeckého kompletu, ktorý pozostáva z bežeckých lyží, bežeckých topánok a bežeckých paličiek.</p>
          </template>
          <template slot="image">
            <Imgix src="images/pozicovna/pozicovna_bezky.jpg" alt="" />
          </template>
        </detail-box>

        <detail-box title="SKIALPOVÁ VÝSTROJ" v-bind:pricelist="[['Skialpový komplet s rámovým viazaním', 25, 45, 60, 70, 75, 80], ['Skialpový komplet s techovým viazaním', 30, 50, 65, 75, 85, 95]]" class="box" image="pozicovna/skialpova-vystroj.jpg" bg="#f71735" ref="6">
          <p>Vyskúšajte skialpinizmus a spoznajte zasnežené kopce inak - po vlastných.</p>
          <template slot="popis">
            <p>Požičanie skialpového kompletu, ktorý pozostáva zo skialpových lyží so skialpovým viazaním, pásov, skialpových lyžiarok a teleskopických skialpových paličiek.</p>
          </template>
          <template slot="image">
            <Imgix src="images/pozicovna/pozicovna_skialp.jpg" alt="" />
          </template>
        </detail-box>

        <detail-box title="OSTATNÉ VYBAVENIE" v-bind:pricelist="[['Lyžiarky', 9, 17, 24, 30, 35, 40], ['Palice, prilba', 3, 5, 7, 8, 9, 10], ['Sánky, boby, Big Foot', 5, 9, 13, 16, 19, 22]]" class="box" image="pozicovna/ostatne-vybavenie.jpg" bg="#999" ref="7">
          <p>Komplexná ponuka - lyžiarske topánky, lyžiarske palice, lyžiarske okuliare, prilby, chrániče chrbátu, bigfooty, snowblade, sánky, boby, …</p>
          <template slot="image">
            <Imgix src="images/pozicovna/pozicovna_ostatne.jpg" alt="" />
          </template>
        </detail-box>
      </div>

      <div class="full-box bg-dokonaly-zazitok">
        <div class="full-box-content">
          <h3 style="color: #f71735">DOKONALÝ ZÁŽITOK NA SNEHU</h3>
          <p><font-awesome-icon icon="check-circle" /> výber zo širokej ponuky renomovaných značiek</p>
          <p><font-awesome-icon icon="check-circle" /> výstroj v požičovni je každý rok doplnený o najnovšie modely</p>
          <p><font-awesome-icon icon="check-circle" /> profesionálny servis před každým zapožičaním výstroje na strojoch MONTANA</p>
        </div>
      </div>

      <div class="full-box content-right bg-kvalita-sluzieb">
        <div class="full-box-content">
          <h3 style="color: #96d400">KVALITA SLUŽIEB</h3>
          <p><font-awesome-icon icon="check" /> 1 deň požičania zadarmo - za každých 6 dní</p>
          <p><font-awesome-icon icon="check" /> výmena veľkosti požičanej výstroje rovnakej kvality zadarmo</p>
          <p><font-awesome-icon icon="check" /> obuv a helmy sú ošetrené a 100% hygienicky čisté</p>
        </div>
      </div>

      <div class="full-box bg-radost-zo-snehu">
        <div class="full-box-content">
          <h3 style="color: #01a0e2">RADOSŤ ZO SNEHU</h3>
          <p>Pre plné využite zimnej sezóny ponúkame i ďalšie vybavenie:</p>
          <p><font-awesome-icon icon="check-circle" /> snowblade a big foot</p>
          <p><font-awesome-icon icon="check-circle" /> sánky a boby</p>
        </div>
      </div>
    </article>
    <app-footer>
      <b>Požičovňa lyžiarskej a snowboardovej výstroje:</b>
        <ul>
          <li><a @click="openModal('1')">VIP zjazdové lyže</a></li>
          <li><a @click="openModal('2')">STANDARD zjazdové lyže</a></li>
          <li><a @click="openModal('3')">DETSKÉ/JUNIOR zjazdové lyže</a></li>
          <li><a @click="openModal('4')">Snowboard</a></li>
          <li><a @click="openModal('5')">Bežecká výstroj</a></li>
          <li><a @click="openModal('6')">Skialpová výstroj</a></li>
          <li><a @click="openModal('7')">Ostatné vybavenie</a></li>
        </ul>
    </app-footer>
  </div>
</template>

<style lang="scss" scoped>
  $title-color: #0968aa;

  .bg-dokonaly-zazitok {
    background-image: url(https://skiservis.imgix.net/pozicovna/dokonaly-zazitok.jpg?auto=format&dpr=1);
    background-color: #ebf0f6;

    @media only screen and (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        background-image: url(https://skiservis.imgix.net/pozicovna/dokonaly-zazitok.jpg?auto=format&dpr=2);
    }

    @media only screen and (min-device-pixel-ratio: 3), (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
        background-image: url(https://skiservis.imgix.net/pozicovna/dokonaly-zazitok.jpg?auto=format&dpr=3);
    }
  }

  .bg-kvalita-sluzieb {
    background-image: url(https://skiservis.imgix.net/pozicovna/kvalita-sluzieb.jpg?auto=format&dpr=1);
    background-color: #fff;

    @media only screen and (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        background-image: url(https://skiservis.imgix.net/pozicovna/kvalita-sluzieb.jpg?auto=format&dpr=2);
    }

    @media only screen and (min-device-pixel-ratio: 3), (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
        background-image: url(https://skiservis.imgix.net/pozicovna/kvalita-sluzieb.jpg?auto=format&dpr=3);
    }
  }

  .bg-radost-zo-snehu {
    background-image: url(https://skiservis.imgix.net/pozicovna/radost-zo-snehu.jpg?auto=format&dpr=1);
    background-color: #d5e3e6;

    @media only screen and (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        background-image: url(https://skiservis.imgix.net/pozicovna/radost-zo-snehu.jpg?auto=format&dpr=2);
    }

    @media only screen and (min-device-pixel-ratio: 3), (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
        background-image: url(https://skiservis.imgix.net/pozicovna/radost-zo-snehu.jpg?auto=format&dpr=3);
    }
  }

  .content-wrapper {
    width: 90%;
    margin: 1rem auto;

    & > p {
      font-size: 18px;
      margin: 1rem 0;
    }
  }

  h1 {
    display: none;
  }

  h2 {
    color: $title-color;
    font-size: 30px;
    margin: 2.5rem 0 2rem;
    text-transform: uppercase;
  }

  .boxes {
    .box {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.25);
        transform: rotateZ(.5deg);
        z-index: -10;
        filter: blur(3px);
        border-radius: 5px;
      }

      ::v-deep h3 {
        font-family: "Montserrat";
        font-size: 23px;
        color: #fff;
        margin: -3.5rem 0 0;
        padding: .5rem 1rem;
        text-transform: uppercase;
        position: relative;
        background: rgba(0,0,0,.5);
      }
      
      ::v-deep .sweet-content {
        @media (min-width: 1024px) {
          aside {
            float: right;

            img {
              width: 450px;
              margin-left: 20px;
            }
          }
        }

        p {
          color: #444;
          font-size: 1rem;
          padding: 1rem 0;
        }

        h4 {
          font-size: 18px;
          font-weight: bold;
          clear: right;
        }

        table {
          margin: 1rem 0;
          padding: 0;
          width: 100%;
          font-size: 1rem;

          tr {
            border-bottom: 1px solid #eee;

            td {
              vertical-align: middle;
              padding: .75rem;
            }

            th {
              vertical-align: middle;
              padding: .35rem .75rem;
              background: #d9f3ff;
            }
          }
        }
      }

      ::v-deep .sweet-buttons {
        border: none;
        text-align: center;
        padding-bottom: 2rem;

        @media (max-width: 640px) {
          position: initial;
          bottom: initial;
        }
      }

      ::v-deep .button-action {
        font-weight: normal;
        font-size: 1.1rem;
        border-radius: 2rem;
        padding: 0.5rem 1.5rem;
      }

      p {
        padding: 1rem;
        margin: 0;
        color: #fff;
      }
    }
  }

  .full-box {
    background-size: contain;
    padding: 2rem 3%;

    p {
      line-height: 2;
    }

    h3 {
      font-weight: 200;
      font-size: 30px;
      margin: 1rem 0;
    }

    &.full-box-night-service {
      p {
        color: #fff;
        width: 50%;
      }
    }
  }
</style>
